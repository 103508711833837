<template>
    <div style="text-align: center; margin: 2%;">
        <Title v-if="!done" :config="{title:{'en-ca':'Fetching resource','fr-ca':'Fetching resource'}}"/> 
        <div v-if="asset && isAllowed || grant">
            <template v-if="resource && !(isEnabled || isPublished)">
                <Title  :config="{title:resource.definition.title,subtitle:{'en-ca':'Note: resource is not published or disabled','fr-ca':'Note: resource is not published or disabled'}}"/>
                Standard users will get 404 search results here: <router-link :to="resolve404()">{{ resolve404() }}</router-link>
            </template>
            <ResourceCard v-if="type=='video'" :resource="resource" :language="language" style="max-width: 400px; margin: 50px auto;"/>
        </div>
      
      <Nope v-if="!asset"/>
  
    </div>
  </template>
  
  <script>
  import Title from '@/components/Title/Title'
  import ResourceCard from '@/components/Resources/ResourceCard.vue'
  import Nope from '@/views/404.vue'
  export default {
      name: "GetResource",
      created: function(){
          let resource = this.resource
          let asset = this.asset
          let type = this.type
          let language = this.language
          if(asset && ( this.isAllowed || this.grant)){
              if(type=='document'){
                  let path = `${this.$store.getters.bucket}/${asset}`
                  window.location.replace(path)
              }
              if(type=='link'){
                  if(resource.external=='N'){
                      this.$router.push({path:asset})
                  }else{
                      window.location.replace(asset)
                  }
              }
          }else{
            this.$router.push({path:this.resolve404()})              
          }
          let self = this
          setTimeout(() => {
            self.done = true
          }, 2000);
      },
      components: {
          Title,
          ResourceCard,
          Nope
      },
      data: function(){
          return {
            done: false,
            disabled: [],
            allowThese: ['SuperAdmin','admin','tester']
          }
      },
      methods: {
        resolve404: function(){
            let resource = this.resource
            let language = this.language
            // return `/resource/${resource.id}/${language}?title=${resource.definition.title[language]}`
            if(resource){
                return `/404/?title=${resource.definition.title[language]}`
            }else{
                return `/404/${this.resourceId}`
            }
        }
      },
      computed: {
          bucket: function(){
              return this.$store.getters.bucket
          },
          resourceId: function(){
              let resourceId = this.$route.params.resourceId
              return resourceId
          },
          language: function(){
              let language = this.$route.params.language
              return language ? language : this.$store.getters.language
          },
          resourceMap: function(){
              let output = {}
              let resources = this.$store.getters.resources
              for(let i=0; i<resources.length; i++){
                  let resource = resources[i]
                  output[resource.id] = resource
              }
              return output
          },
          resource: function(){
              let resourceId = this.resourceId
              let language = this.language
              let resourceMap = this.resourceMap
              if(resourceId && resourceMap[resourceId] && language){
                  return resourceMap[resourceId]
              }
              return null
          },
          type: function(){
              let resource = this.resource
              return resource ? resource.definition.type : null
          },
          asset: function(){
              let fallBack = {}
              let resource = this.resource
              let language = this.language
              let languages = this.$store.getters.languages
              if(resource){
                  for(let i=0; i<languages.length; i++){
                      let lang = languages[i]
                      fallBack[lang] = resource.definition.resource.asset[lang]
                  }
              }else{
                  return false
              }
  
              
              let asset = resource.definition.resource.asset[language]
              if(asset){
                  return asset
              }else{
                  for(let lang in fallBack){
                      if(fallBack[lang]){
                          return fallBack[lang]
                      }
                  }
              }
  
              return false
  
          },
          isEnabled: function(){
              let resource = this.resource
              if(resource){
                  return resource.enabled=='Y'
              }
              return false
          },
          isPublished: function(){
              let resource = this.resource
              if(resource){
                  return resource.publish=='Y'
              }
              return false
          },
          isPublic: function(){
              let resource = this.resource
              if(resource){
                  return resource.public=='Y'
              }
              return false
          },
          isAllowed: function(){
              let isEnabled = this.isEnabled
              let isPublished = this.isPublished
              let isPublic = this.isPublic
              return (isEnabled && isPublished && isPublic)
          },
          grant: function(){
              let allow = false
              let allowThese = this.allowThese
              let groups = this.$store.getters.user ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
              for(let i=0; i<allowThese.length; i++){
                  let group = allowThese[i]
                  if(groups.includes(group)){
                      allow = true;
                  }
              }
              return allow
          }
  
      }
  }
  </script>
  
  <style>
  
  </style>