<template>
<v-container>
    <v-row>
        <v-col>
            <center>
                <h4 v-if="!ready">
                    ...
                </h4>
                <h4 v-else>
                    <h3>404 error</h3>
                    Click below to go
                    <br>
                    <router-link to="/">Home</router-link>
                </h4>
            </center>

            <template v-if="ready">
                <h4>Related Results:</h4>
                We searched the site for: {{query}}

                <div v-if="ready">
                    <template  v-if="foundSomething">
                        <v-tabs v-model="ui.tab">
                            <template v-for="(results, key ) in searchResults">
                                <v-tab v-if="results && results.length>0" :key="`tab-selector-${key}`">
                                    {{key}}
                                </v-tab>
                            </template>
                        </v-tabs>
        
                        <v-tabs-items v-model="ui.tab">
                            <template v-for="(results, key ) in searchResults">
                                <v-tab-item v-if="results && results.length>0" :key="`tab-item-${key}`">
                                    <template v-if="key=='guides'">
                                        <h4>Clinical Guide(s) related to your request:</h4>
                                        <GuideCard v-for="guide_id in compileResults(searchResults.guides)" :key="`guide-id-${guide_id}-${language}`" @selectGuide="selectGuide" :guideTopics="guideData.guideTopics" :guide="getGuideById(guide_id)" :language="language"/>
                                    </template>
        
                                    <template v-if="key=='articles'">
                                        <h4>Article(s) related to your request:</h4>
                                        <div style="display: flex; flex-wrap: wrap;">
                                            <ArticleCard v-for="(path, index) in compileResults(searchResults.articles)" :key="`page-id-${index}`" :params="getArticleByPageName(path)" :language="language"/>
                                        </div>
                                    </template>
        
                                    <template v-if="key=='site'">
                                        <h4>Page(s) related to your request:</h4>
                                        <v-list>
                                            <v-list-item v-for="(path, index) in compileResults(searchResults.site)" :key="`page-id-${index}`">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{getPageByPath(path).meta.label[language]}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <router-link :to="path">{{path}}</router-link>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>    
                                    </template>
        
                                    <template v-if="key=='resources'">
                                        <h4>Resource(s) related to your request:</h4>
                                        <ResourceCard style="display: inline-block; margin: 15px; width: 300px;" v-for="resource_id in compileResults(searchResults.resources)" :key="`resource-id-${resource_id}-${language}`" :resource="getResourceById(resource_id)" :language="language"/>    
                                    </template>
                            
                                </v-tab-item>
                            </template>
                        </v-tabs-items>                    
                    </template>

                    <h5 v-else align="center">Nothing found</h5>
                </div>
                <center v-else>...</center>



            </template>


        </v-col>
    </v-row>

</v-container>
</template>

<script>
import ResourceCard from '@/components/Resources/ResourceCard'
import GuideCard from '@/components/ClinicalGuides/guideCard'
import ArticleCard from '@/components/Article/lib/articleCard'
export default {    
    mounted: function(){
        this.init()
        // setTimeout(function(){
        //     self.$router.push('/')
        // }, 3000)
    },
    components: {
        ResourceCard,
        GuideCard,
        ArticleCard
    },
    data: function(){
        return {
            ready: false,
            ui: {
                tab: 0
            },
            searchResults: null
        }
    },
    methods: {
        init: function(){
            let documents = this.documents
            let ready = true
            for(let key in documents){
                if(!documents[key]){
                    ready = false
                }
            }
            this.ready = ready

            this.redirect()
            if(ready){
                this.search()
            }
        },
        redirect: function(){
            let redirects = this.$store.getters.redirects
            let path = this.path
            for(let i=0; i<redirects.length; i++){
                let record = redirects[i]
                if(record.path == path && record.enabled=='Y'){
                    this.$router.push({path: record.redirect, query: this.$route.query})
                }
            }
        },
        fileType: function(string){
            if(string.indexOf('.')<0){
                return false
            }

            let components = string.split('.')
            let extension = components[components.length-1].toLowerCase()
            return extension
        },
        search: function(){
            let documents = this.documents

            // console.log('search',{data, query})
            let query = this.query
            let results = {
                site: documents.site ? documents.site.search([{field:"text",query}]) : null,
                guides: documents.guides ? documents.guides.search([{field:"name",query},{field:"objective",query},{field:"html",query}]) : null,
                articles: documents.articles ? documents.articles.search([{field:"topics",query}, {field:"type",query}, {field:"title",query}, {field:"subtitle",query}, {field:"body",query}]) : null,
                resources: documents.resources ? documents.resources.search([{field:"title",query}, {field:"subtitle",query}, {field:"type",query}, {field:"category",query}, {field:"description",query}, {field:"link",query}, {field:"asset",query}]) : null
            }

            this.searchResults = results
        },
        compileResults: function(results){
            let collection = []
            for(let i=0; i<results.length; i++){
                let record = results[i]
                for(let ii=0; ii<record.result.length; ii++){
                    let id = record.result[ii]
                    if(!collection.includes(id)){
                        collection.push(id)
                    }
                }
            }
            return collection
        },
        map: function(array, key='id'){
            let output = {}
            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item[key]] = item
            }
            return output
        },
        getGuideById: function(guideID){
            let language = this.language
            let guides = this.$store.getters.clinicalGuideData[language].guides
            let results = guides.filter((guide)=>{return guide.id==guideID})
            return results[0]
        },
        getResourceById: function(resource_id){
            let resources = this.resources
            let resource = this.map(resources,'id')[resource_id]
            return resource
        },
        getArticleByPageName: function(pageName){
            let articles = this.$store.getters.articles
            let results = articles.filter((article)=>{return article.pageName==pageName})
            return results[0]
        },
        selectGuide: function(data){
            this.$router.push({
                path: '/hcp/practice/clinical_guides',
                query: {
                    language: this.language,
                    guideID: data.id
                }
            })
        },
        downloadResource: function(resource){
            console.log('downloadResource',{
                resource,
                bucket: this.$store.getters.bucket
            })
            window.open(`${this.$store.getters.bucket}/${resource}`);            
        },
        getPageByPath: function(path){
            let site = this.$store.getters.site.home
            function getPage(path){
                let stack = path.split('/')
                let target = ''
                for(let i=0; i<stack.length; i++){
                    let record = stack[i]
                    if(record && record.length>0){
                        target += `['_pages']['${record}']`
                    }
                }
                let resolution = eval(`site${target}`)
                return resolution
            }

            return getPage(path)
        }
    },
    computed: {
        foundSomething: function(){
            let searchResults = this.searchResults
            if(searchResults){
                for(let key in searchResults){
                    let results = searchResults[key]
                    if(results && results.length>0){
                        return true
                    }
                }
            }
            return false
        },
        guideData: function(){
            let language = this.language
            return this.$store.getters.clinicalGuideData ? this.$store.getters.clinicalGuideData[language] : null
        },
        path: function(){
            let path = this.$route.path
            if(path.lastIndexOf('/') == path.length-1){
                return path.substr(0, path.length-1)
            }else{
                return path
            }
        },
        documents: function(){
            return {
                site: this.$store.getters.siteDocument ? this.$store.getters.siteDocument : false,
                guides: this.$store.getters.guidesDocument ? this.$store.getters.guidesDocument : false,
                articles: this.$store.getters.articlesDocument ? this.$store.getters.articlesDocument : false,
                resources: this.$store.getters.resourcesDocument ? this.$store.getters.resourcesDocument : false,
            }
        },
        oldWordPressAsset: function(){
            let trigger = 'wp-uploads'
            let path = this.path
            let parts = path.split('/')
            return parts.includes(trigger)
        },
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        signedIn: function(){
            return this.$store.getters.user ? true : false
        },
        _request: function(){
            let output = {}
            let path = this.path
            let resources = this.resources
            output = {
                raw: path,
                fileType: this.fileType(path),
                resources
            }
            return output
        },
        resources: function(){
            return this.$store.getters.resources
        },
        query: function(){
            let path = this.path.replaceAll('/404','')
            let fileType = this.fileType(path)
            let parts = path.split('/')
            let query = parts[parts.length-1].replaceAll('%20',' ')
            for(let key in this.$route.query){
                query += ` ${this.$route.query[key]}`
            }
            return query
        }
    },
    watch: {
        documents: {
            deep: true,
            handler: function(){
                this.init()
            }
        }
    }
}
</script>

<style>

</style>